@import "styles/colors";
@import "styles/font";
@import "styles/forms";
@import "styles/util";
@import "styles/main";
@import "styles/responsive";

.fill-height {
  height: 100%;
  min-height: 100%;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.fill-width {
  min-width: 1024px;
}

.fill {
  @extend .fill-height;
  @extend .fill-width;
}

#app {
  @extend .fill;
}

.alert-success {
  .MuiSnackbarContent-root {
    background: green;
  }
}

.alert-failure {
  .MuiSnackbarContent-root {
    background: red;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.router-link {
  @extend .font-300;

  text-decoration: none !important;
  color: #000 !important;
}

.MuiInputBase-input,
.MuiButton-label {
  font-weight: 300 !important;
}

.sia-icon-button {
  background: $sia-yellow !important;
  color: #fff !important;

  &.disabled {
    @extend .bg-light-gray;
  }
}

.sia-icon-button-inverted {
  color: $sia-yellow !important;
  background: #fff !important;

  &.disabled {
    @extend .bg-light-gray;
  }
}

.form-group-container {
  .form-control-perso {
    height: 70px !important;
  }
}

.article-content img {
  max-width: 100% !important;
}
.form-textarea-group-container {
  .form-control-textarea {
    max-height: 200px !important;
    min-height: 200px !important;
  }
}

.form-control-textarea,
.form-control-perso {
  width: 100% !important;
  outline: none;
  background: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  box-sizing: border-box !important;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #828282;
}

.image-gallery-slide .image-gallery-description {
  font-weight: bold !important;
  bottom: unset !important;
  top: 20 !important;
  left: 20 !important;
}

.image-gallery-thumbnail-image {
  height: 80px;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px !important;
  width: 40px !important;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 24px 6px rgba(74, 74, 74, 0.44);
  box-shadow: 0px 0px 12px 0px rgba(74, 74, 74, 0.33);
}
.image-gallery-slide .image-gallery-image {
  width: 85% !important;
  object-fit: cover !important;
}
