.textual-item.selected {
    display: block !important;
}
.textual-item {
    display: none;
}
.slider-single.active .slider-single-content .caroussel-image-item-container img {
    width: 200px;
    height: 200px;
    border: 10px solid #EE3434;
}
.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    box-shadow: none !important;
}
.caroussel-image-item-container {
    list-style: none;
}
.caroussel-image-item-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.slider-content .slider-single.proactive .caroussel-image-item-container img {
    margin-top: 55px !important;
    margin-left: 65px !important;
}
.slider-content .slider-single.preactive .caroussel-image-item-container img {
    margin-top: 55px !important;
    margin-right: 65px !important;
}
.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
    opacity: .7 !important;
}
.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
    opacity: .7 !important;
}
.slider-right .fa, .slider-left .fa {
    color: #000;
}
.react-3d-carousel .slider-container .slider-right div {
    border-top: 2px solid #EE3434 !important;
    border-right: 2px solid #EE3434 !important;
    border-bottom: 2px solid #EE3434 !important;
    border-left: 2px solid #EE3434 !important;
}
.react-3d-carousel .slider-container .slider-left div {
    border-top: 2px solid #EE3434 !important;
    border-right: 2px solid #EE3434 !important;
    border-bottom: 2px solid #EE3434 !important;
    border-left: 2px solid #EE3434 !important;
}
#btn-control .slider-left {
    display: block !important;
}
#btn-control .slider-right {
    display: block !important;
}
.bloc-carousel-client-container {
    height: 400px;
}
.text-temoignage-client {
    max-height: 200px;
    overflow-y: hidden;
    margin-bottom: 10px;
}