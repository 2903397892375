.infos-perso-input-testimonials {
  margin-bottom: 2.5rem !important;
}

.modal-testimonials-content {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
}

.modal-name-testimonials {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 154.4%;
  color: #ee3434;
}

.modal-fonction-testimonials {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 154.4%;
  color: #000000;
  margin-top: -10px;
}

.modal-paragraph-testimonials {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 154.4%;
  color: #000000;
}

.card-testimonials-section {
  position: relative;
}

.modal-icons-testimonials {
  position: absolute;
  right: 20px;
  bottom: 10px;
  display: flex;
  gap: 15px;
}

.fghjk {
  border-radius: 10px;
}

.modal-img-testimonials {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.profile-containers-testimonials {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icone-bg-testimonials {
  background: none;
  text-decoration: none;
  border: none;
}
@media (max-width: 768px) {
  .modal-testimonials-content {
    gap: 10px;
  }
}

@media (max-width: 767px) {
  .profile-containers-testimonials {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.ck-content,
p {
  color: #000000;
}
