.font-12 {
  font-size: 12px;
}

.font-18 {
  font-size: 18px;
}

.font-300 {
  font-weight: 300;
}

.font-500 {
  font-weight: 500;
}

.font-italic {
  font-style: italic;
}
