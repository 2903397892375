.card-connexion {
  padding: 1rem 13%;
}
.titre-connexion {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #1c1c1c;
  margin-bottom: 20px;
}
.text-ou {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #1c1c1c;
}
