.container-profile-article {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-container {
  min-width: 450px !important;
  height: auto !important;
  overflow: hidden !important;
  position: relative !important;
  /* margin: 15px auto !important; */
  border: 1px solid transparent !important;
  /* border-radius: 50% !important; */
  cursor: pointer !important;
}

.image-container img {
  width: 100%;
  min-height: 200px;
  object-fit: fill;
  height: 20vw;
}

@media (max-width: 767px) {
  .container-profile-article {
    flex-direction: column;
    align-items: start;
  }
}
