footer {
  background: #cc1b0b;
}
.head-footer {
  border-bottom: 1px solid #5a5a5a;
}
.body-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer .logo {
  width: 160px;
}
.link-top-footer {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.231818px;
  color: #ffffff;
  background: #cc1b0b;
  border-radius: 8px;
  padding: 0.7rem 2rem 0.7rem 2rem;
}
.text-footer-span {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.link-top-footer:hover {
  color: #cc1b0b;
  border: #cc1b0b;
  background-color: #fff;
  text-decoration: none;
}
.footer-grid .title-footer {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.231818px;
  color: #fff;
}
.input-email-footer {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: #21232a;
  background: #fff;
  border: none;
  border-bottom: 1px solid #fff !important;
  padding: 1rem;
  width: 75%;
}
.btn-submit-footer {
  background: #cc1b0b;
  border-radius: 0px 1px 0px 0px;
  padding: 1rem;
  border: 1px solid #fff;
}
.icon-submit-footer {
  color: #fff;
}
.text-footer {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: #ffffff;
}
/* .icon-social-footer {
    width: 9px;
    height: 17px;
} */
.footer-grid .text-content {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #ffffff !important;
}
.footer-grid .link-footer {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: #fff;
}
.footer-grid .link-footer:hover {
  text-decoration: none;
  color: #fff;
}
.footer-grid .contact-text {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #ffffff !important;
  text-align: left;
}
.footer-grid .contact-text:hover {
  color: #ffffff !important;
  text-decoration: none;
}
.footer-grid .contact-text > svg {
  width: 14px;
  position: absolute;
  left: 15px;
}
.social-networking p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.social-networking a {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.social-networking span {
  padding-left: 2rem;
  padding-right: 2rem;
}
.social-networking a:hover {
  transform: scale(1.2);
  opacity: 1;
}
.social-networking img {
  width: 100%;
}

footer .copyright {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  display: inline-block;
}
.link-end-footer {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
}
.link-end-footer:hover {
  text-decoration: none;
  color: #fff;
}
.btn-n-c-f-c {
  background: transparent;
}

#formulaireDeContact {
  z-index: 9999999;
}

.container-taf-footer {
  padding-left: 4rem;
  padding-right: 4rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.toTop {
  background: #0e0e0e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 1rem;
}
