.icon-search-dashboard {
  color: #aab8c6;
}

.s130 form .inner-form {
  border-radius: 34px;
  overflow: hidden;
}

.s130 form .inner-form .input-field {
  height: 50px;
}

.s130 form .inner-form .input-field input {
  height: 100%;
  background: transparent;
  border: 0;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.s130 form .inner-form .input-field input.placeholder {
  color: #222;
  font-size: 16px;
}

.s130 form .inner-form .input-field input:-moz-placeholder {
  color: #222;
  font-size: 16px;
}

.s130 form .inner-form .input-field input::-webkit-input-placeholder {
  color: #222;
  font-size: 16px;
}

.s130 form .inner-form .input-field input:hover,
.s130 form .inner-form .input-field input:focus {
  box-shadow: none;
  outline: 0;
}

.s130 form .inner-form .input-field.first-wrap {
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background: #f4f4f4;
}

.s130 form .inner-form .input-field.first-wrap input {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.s130 form .inner-form .input-field.first-wrap .svg-wrapper {
  min-width: 80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.s130 form .inner-form .input-field.first-wrap svg {
  width: 25px;
  height: 25px;
}

.s130 form .inner-form .input-field.second-wrap {
  min-width: 216px;
}

.s130 form .info {
  font-size: 15px;
  color: #ccc;
  padding-left: 26px;
}

@media screen and (max-width: 992px) {
  .s130 form .inner-form .input-field {
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .s130 form .inner-form .input-field.first-wrap .svg-wrapper {
    min-width: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
  }
  .s130 form .inner-form .input-field.first-wrap svg {
    width: 26px;
    height: 26px;
  }
  .s130 form .inner-form .input-field.second-wrap {
    min-width: 100px;
  }
}
// This style is used in taf project

.form-custom-label {
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #333333;
}

.form-custom-control,
.form-custom-control-textarea {
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  outline: none;
  border: none;
}

.form-custom-control {
  height: 63px;
}

.form-custom-control-textarea {
  height: 145px;
}

.form-custom-control::placeholder {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #828282;
}

.custom-label,
.custom-label sup {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  color: #000000;
}
