.profile-containers {
  background: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  border-radius: 15px;
  padding: 1.5rem 2rem;
}
.bloc-general {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.bloc-principal-body-content {
  width: 100%;
  padding: 2.5rem !important;
}
.admin-container {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 3rem;
}

.container-profile-edit-reglage {
  display: flex;
  justify-content: flex-end;
}
/* .icon-edit {
  color: #9a160a;
  
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 154.4%;
} */
.btn-profile-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}
.btn-profile-admin {
  border-color: #c0c0c0 !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;

  color: #9a160a;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 154.4%;
}
.btn-profile-admin:hover {
  background-color: #9a160a;
  border: 1px solid #9a160a;
  color: #fff;
}

.mon-profil {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
.information-personnelle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #115c67;
}
.text-jones {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: #252733;
}
.text-admin {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: #252733;
  text-align: center;
}
.profile-image-admin {
  margin-top: 55px;
}
.btn-parametre-admin {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ababab;
}

.btn-parametre-icon {
  color: #115c67;
  width: 23px;
  height: 23px;
  margin-right: 5px;
}

.container-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  /* height: 50px; */
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  background: #ffffff;
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
}
.form-control-article {
  position: relative;
  display: block;
  width: 100%;
  height: 150px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  background: #ffffff;
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  overflow: scroll;
}

.form-input-profile {
  padding-top: 70px;
}
.container-input-address {
  padding-top: 50px;
}

.form-label-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #252733;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}

.profile-avatar-container {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: #fff8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: #115c67 !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
  color: #115c67 !important;
}
.profile-avatar-container:hover span {
  bottom: -15% !important;
}
.close-icon-pointe {
  cursor: pointer;
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}

/* --------responsive---------- */

@media (max-width: 375px) {
  .text-jones {
    text-align: left;
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .container-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
