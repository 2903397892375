.admin-contacts-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
.card-contacts-main-content {
  width: 98%;
  margin: 20px auto;
  padding-bottom: 60px;
}
.div-card {
  background: #ffffff !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08) !important;
  border-radius: 5px !important;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.faq-card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #06640c;
}
.contact-btn-nouveau {
  background: #f3bb67;
  border-radius: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: none;
  outline: none;
}
.contact-btn-nouveau {
  background: #9a160a;
  border-radius: 100px;
  color: #ffffff;
}
.contact-btn-nouveau:hover {
  color: #ffffff !important;
}

.contact-btn-treaty {
  background: #f3bb67;
  border-radius: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: none;
  outline: none;
}
.contact-btn-treaty {
  background: #1d9a0a;
  border-radius: 100px;
  color: #ffffff;
}
.contact-btn-treaty:hover {
  color: #ffffff !important;
}

.div-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.div-nouveau-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-name-para {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #06640c;
}
.contact-card-para {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #babec6;
}

.faq-card-title-couper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .div-nouveau-name {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
  }

  .contact-btn-nouveau {
    font-size: 8px !important;
  }

  .contact-name-para {
    font-size: 14px;
  }

  .div-para-contact-modal {
    height: 150px;
    overflow: scroll;
  }
}
