.bar-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
}
.text-short {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #babec6;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bar-chart-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.bar-chart-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #061a07;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bar-chart-title-lorem {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #babec6;
}
.bar-chart-text-container {
  text-align: justify;
}
.timeline-chart-select {
  background-color: #f7f7f7;
  border-radius: 10px !important;
  color: black;
  border: none !important;
}
.timeline-chart-select:hover {
  background-color: #f7f7f7;
  border-radius: 10px !important;
  color: black;
}
.timeline-chart-select:focus {
  background-color: #f7f7f7;
  border-radius: 10px !important;
  color: black;
}

.timeline-chart-select,
.timeline-chart-select > option {
  font-style: normal !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  /* grayscale / black */
  color: #252733;
}

.timeline-chart-select:focus {
  border: none !important;
  box-shadow: none !important;
}

.dashboard-chart-container {
  background: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  padding: 1.5rem 2rem;
  margin-top: 3rem;
}

.dashboard-chart-info-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-chart-info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #2d3748;
  margin: 0;
  margin-bottom: 10px;
}

.dashboard-chart-info-stat {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9a160a;
  margin: 0;
}

.dashboard-chart-filter {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  height: 45px;
  position: relative;
}

.dashboard-chart-filter-select {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  height: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
}

.dashboard-chart-filter-icon {
  position: absolute;
  right: 3px;
  top: 10px;
  color: #9a160a;
}
