@media (max-width: 370px) {
  .d-m-none {
    display: none !important;
  }
}
@media (max-width: 420px) {
  .flex-col-mobile {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}
@media (max-width: 575px) {
  .view-sm {
    display: none;
  }
  .bloc-carousel-client-container {
    height: 700px !important;
  }
  .text-temoignage-client {
    max-height: 400px !important;
  }
}
@media (min-width: 576px) {
  .img-featured-property-card {
    width: 35% !important;
  }
  .show-of-image {
    height: 250px !important;
  }
}
@media (min-width: 768px) {
  .no-view-desktop {
    display: none;
  }
  .p-contact {
    padding-top: 130px !important;
  }
  .img-listing-card-item {
    max-width: 370px !important;
    min-width: 370px !important;
    min-height: 280px !important;
    max-height: 280px !important;
  }
  .show-of-small-size {
    height: 150px !important;
  }
  .show-of-image-country {
    height: 420px !important;
  }
  .show-of-text-country-container {
    top: 47% !important;
  }
  .banniere-about-us {
    height: 320px !important;
  }
}
@media (max-width: 767px) {
  .no-view-mobile {
    display: none;
  }
  #carousel-accueil .carousel-indicators {
    bottom: 9% !important;
  }
  .container-page-about-home {
    padding-top: 243px !important;
  }
  .menu-item {
    padding: 10px 21px !important;
  }
  #navbarSupportedContent {
    margin-top: 6%;
  }
  .titre-banniere,
  .titre-banniere p {
    font-size: clamp(16px, 2.5vw, 28px) !important;
    line-height: 54px !important;
  }

  .bloc-contain-titre-featured-listing {
    display: block !important;
  }

  .no-border-mobile {
    border-left: none !important;
    border-right: none !important;
  }
  .no-p-mobile {
    padding: 0.5rem 0 !important;
  }
  .container-taf {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .div-content-header-contact {
    padding: 15px !important;
  }
  .menu-link.active:after {
    right: inherit !important;
    left: 26px !important;
    right: 20px !important;
    width: inherit !important;
  }
  .ml-logo-desktop {
    margin-left: inherit !important;
  }
  .pb-mobile {
    padding-bottom: 1rem;
  }
  .no-mt-mobile {
    margin-top: inherit !important;
  }
  .container-taf-footer {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .input-email-footer {
    width: 85% !important;
  }
  .footer-form-langue {
    width: 50% !important;
  }
  .no-pl-mobile {
    padding-left: inherit !important;
  }
  .px-footer-mobile {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .justify-content-mobile {
    justify-content: space-evenly;
  }
  .p-t-200 {
    padding-top: 50px !important;
  }
  .pb-banniere-mobile {
    padding-bottom: 50px;
  }
  .img-equipe {
    width: 100%;
  }
  .card-connexion {
    padding: 1rem 10px !important;
  }
  .text-right-mobile {
    text-align: center !important;
  }
  .row {
    margin-right: inherit !important;
    margin-left: inherit !important;
  }
  .card-profile-choice {
    padding: 0.5rem 0 !important;
  }
  .titre-profile-choice {
    padding-left: 0.8rem;
  }
  .div-container-statut {
    padding: 0.5rem !important;
  }
  .no-pb-form-mobile {
    padding-bottom: 0 !important;
  }
  .card-item-listing-mobile {
    display: block !important;
  }
  .img-projets-card-item {
    width: 100% !important;
  }
  .no-display-flex-mobile {
    display: block !important;
  }
  .p-theme-collapse-position {
    left: 8% !important;
  }
  .bloc-entete-right {
    display: block !important;
  }
  .bloc-entete-right > div {
    padding-left: inherit !important;
    padding-bottom: 5px;
  }
  .card-item-listing-mobile {
    display: block !important;
  }
  .bottom-card-item-listing {
    width: 80% !important;
  }
  .input-search-space-name {
    font-size: 12px !important;
  }

  .author-comment-info-container {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  .comment-text-container {
    padding-left: 0 !important;
  }
  .img-fluid {
    width: 90% !important;
    height: 90% !important;
  }
  .gallery-img {
    width: 100% !important;
    height: 90% !important;
  }
  .tabs-container {
    min-height: 10rem !important;
  }
  .box {
    top: 48% !important;
    left: -64px !important;
  }
  .carousel-indicators {
    bottom: 50px !important;
  }
  .div-container-titre-banniere-accueil {
    margin-top: -2%;
    top: inherit !important;
    left: 21% !important;
  }
  .btn-filter-form {
    width: 100%;
  }
  .caroussel-big-parent {
    display: block !important;
  }
  .caroussel-images-container {
    min-height: 10em !important;
  }
  .itm-center {
    display: block !important;
  }
  .date-stay {
    padding-bottom: 10px;
    margin-right: 0 !important;
  }
  .btn-book {
    padding: 0.5rem !important;
    width: 100%;
  }
  .date-picker-custom {
    width: 100% !important;
  }
  .done-text {
    text-align: justify !important;
  }
  .container-first-header-mobile {
    flex-wrap: wrap;
  }
  .header-contact {
    border-right: inherit !important;
    margin-top: 5px;
  }
  .carousel-container {
    margin-top: 54% !important;
  }
  .d-flex-container-mobile {
    justify-content: space-between;
    width: 100%;
  }
  .img-social-icon-header {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575px) {
  .container-bloc-filter {
    margin-top: -10% !important;
  }
  .btn-see-all {
    font-size: 20px !important;
  }
  .container-heart-icon {
    top: 43% !important;
  }
  .bloc-contain-prix {
    bottom: 56% !important;
  }
  .justify-bloc-mobile {
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  .titre-banniere,
  .titre-banniere p {
    line-height: 28px !important;
    white-space: inherit !important;
  }
  .section-images .row {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .card-statistique-text {
    font-size: 10px !important;
  }
  .w-full-mobile {
    width: 100% !important;
  }
  .ml-auto-header {
    margin-left: auto;
  }
  .ml-img-mobile {
    margin-left: 0;
  }
  .pl-link-item-header-mobile {
    padding: 0 10px !important;
  }
  .bloc-contact-header-mobile {
    width: 100%;
    justify-content: end;
  }
  .img-resp-detail {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 992px) {
  .menu-link.active:after {
    bottom: -1px !important;
  }
  .row {
    margin-left: inherit !important;
    margin-right: inherit !important;
  }
  .img-resp-detail {
    width: 100%;
    height: 100%;
    margin-top: 8px;
  }

  .box span {
    width: 15px !important;
    height: 15px !important;
  }
  .box p {
    font-size: 20px !important;
    font-weight: bold;
  }
  .div-container-p-scroll {
    left: 18px !important;
    top: -20px !important;
  }
  .container-bloc-filter {
    display: block !important;
    width: 100% !important;
  }

  #carousel {
    height: 332px !important;
    transform: translateY(-5%) !important;
  }
  #carousel div.selected {
    left: 50% !important;
  }
  #carousel {
    width: 100% !important;
    height: 740px !important;
    text-align: center;
    padding-top: 23% !important;
    padding-bottom: 48% !important;
  }
  #carousel div.selected .container-temoignage {
    margin-left: -264px !important;
    margin-top: 223px !important;
  }
  #carousel div.selected .bloc-flex-img-text {
    display: block !important;
    width: 100% !important;
  }
  .bloc-flex-img-text {
    width: 100% !important;
  }
  &.prev {
    display: none;
    .previmg {
      display: none;
    }
  }
  &.next {
    display: none;
    .nextimg {
      display: none;
    }
  }
  #carousel div {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .container-listing {
    padding-top: 15vh !important;
  }
}
@media (max-width: 767px) and (min-width: 575px) {
  .bloc-slider-pt-mobile {
    padding-top: 145px !important;
  }
  .btn-see-all {
    font-size: 20px !important;
  }
  .container-heart-icon {
    top: 60% !important;
  }
  .bloc-contain-prix {
    bottom: 41% !important;
  }
  .justify-bloc-mobile {
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  .bloc-carousel-client-container {
    height: 500px !important;
  }
  .text-temoignage-client {
    max-height: 300px !important;
  }
}
@media (max-width: 1999px) and (min-width: 992px) {
}
@media (max-width: 1300px) and (min-width: 992px) {
}
@media (max-width: 1200px) and (min-width: 992px) {
  .titre-banniere,
  .titre-banniere p {
    font-size: 28px !important;
    line-height: 53px !important;
  }
  .div-container-titre-banniere-accueil {
    top: 27% !important;
  }
  .box {
    top: 50% !important;
  }
  .footer-form-langue {
    width: 50% !important;
  }
  .container-heart-icon {
    top: 55% !important;
  }
  .bloc-contain-prix {
    bottom: 43% !important;
  }
  .pr-div-tablet {
    padding-right: 1rem !important;
  }
  .featured-listing {
    padding-top: 50px !important;
  }
  .menu-link.active:after {
    left: 21px !important;
  }
  .rec-item-wrapper {
    padding: 15px !important;
  }
  .img-equipe {
    width: 100%;
  }
  .p-theme-collapse-position {
    left: 3% !important;
  }
  #carousel div.next {
    left: 60% !important;
  }
  #carousel div.selected {
    left: 33% !important;
  }
  #carousel {
    padding-top: 19% !important;
    padding-bottom: 83% !important;
    height: 500px !important;
  }
  #carousel div.selected .container-temoignage {
    margin-left: -113% !important;
    margin-top: 244px !important;
  }
  .img-resp-detail {
    width: 100%;
    height: 100%;
    margin-top: 8px;
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .container-taf {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .card-profile-choice {
    padding: 0.5rem 1rem !important;
  }
  .no-border-mobile {
    border-left: none !important;
    border-right: none !important;
  }
  .no-p-mobile {
    padding: 0.5rem 0 !important;
  }
  .container-taf {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .div-content-header-contact {
    padding: 15px !important;
  }
  .menu-link.active:after {
    right: inherit !important;
    left: 26px !important;
  }
  .ml-logo-desktop {
    margin-left: inherit !important;
  }
  .pb-mobile {
    padding-bottom: 1rem;
  }
  .no-mt-mobile {
    margin-top: inherit !important;
  }
  .container-heart-icon {
    top: 48% !important;
  }
  .bloc-contain-prix {
    bottom: 50% !important;
  }
  .div-container-statut {
    padding: 0.5rem !important;
  }
  .row {
    margin-left: inherit !important;
    margin-left: inherit !important;
  }
  .img-equipe {
    width: 100%;
  }
  .rec-item-wrapper {
    padding: 15px !important;
  }
  .box {
    top: 33% !important;
  }
  .div-container-titre-banniere-accueil {
    top: 25% !important;
    left: 30% !important;
  }
  .titre-banniere,
  .titre-banniere p {
    line-height: 49px !important;
  }
  .div-content-img-scroll {
    padding-right: 0%;
    padding-left: 16% !important;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .container-heart-icon {
    top: 49% !important;
  }
  .bloc-contain-prix {
    bottom: 50% !important;
  }
  .justify-bloc-mobile {
    justify-content: space-between;
  }
}
@media (max-width: 1550px) and (min-width: 1201px) {
  #carousel div.selected {
    left: 30% !important;
  }
  #carousel div.next {
    left: 52% !important;
  }
  #carousel div.selected .container-temoignage {
    margin-left: 224px !important;
  }
  #carousel {
    padding-top: 15% !important;
    padding-bottom: 40% !important;
    height: 350px !important;
  }
  .div-container-titre-banniere-accueil {
    top: 23% !important;
  }
  .titre-banniere,
  .titre-banniere p {
    font-size: clamp(24px, 3vw, 30px) !important;
    line-height: 50px !important;
  }
  .box {
    top: 47% !important;
  }
  .btn-filter-form span {
    display: none;
  }
}

@media screen and(min-width: 1024px) {
  .featured-details {
    width: 60% !important;
  }

  .featured-img {
    height: 100% !important;
    width: 100% !important;
  }
  .about-container {
    padding-top: 7.5rem !important;
  }
  .bloc-div-item-service {
    flex-direction: row !important;
  }
}

@media screen and(min-width: 1440px) {
  .featured-details {
    width: 70% !important;
  }
  .featured-img {
    width: 130% !important;
  }
}
@media screen and (min-width: 2500px) {
  .card-blog {
    width: 85%;
  }
  .card-blog img {
    height: auto;
  }
}

@media screen and(min-width: 1200px) {
  .gap-xl {
    gap: 10px;
  }
}
@media (min-width: 1500px) {
  .p-contact {
    padding-top: 90px !important;
  }
  .img-featured-property-card {
    width: 30% !important;
  }
  .container-listing {
    padding-top: 14vh !important;
  }
  .show-of-small-size {
    height: 220px !important;
  }
  .show-of-image-year {
    height: 350px !important;
  }
  .show-of-image-country {
    height: 590px !important;
  }
  .about-container {
    padding-top: 5rem !important;
  }
  .banniere-about-us {
    height: 400px !important;
  }
  // .img-slider-accueil {
  // 	height: 450px !important;
  // }
}
