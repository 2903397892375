$sia-light-blue: rgba(34, 92, 101, 0.2);
$sia-green: #225c65;
$sia-yellow: #a3bb5c;
$light-gray: #eee;
$medium-gray: #999;
$dark-gray: #666;
$red-error: #7f0000;
$green-success: #019b0f;
$yellow-warning: #ffd79e;

.text-error {
  color: $red-error !important;
}

.text-warning {
  color: $yellow-warning !important;
}

.text-success {
  color: $green-success !important;
}

.text-gray {
  color: gray !important;
}

.dark-gray {
  color: $dark-gray !important;
}

.sia-green {
  color: $sia-green !important;
}

.bg-light-gray {
  background: $light-gray !important;
}

.bg-sia-green {
  background: $sia-green !important;
}
