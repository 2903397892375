.agencie-smart-house {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
.text-agencie {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-top: 20px;
  text-align: justify;
}
.smart-house {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-top: 14px;
}
.text-senegal {
  color: #2d3748;
}
.container-detail-image-text {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.agencie-image-smart {
  width: 100%;
  height: 100%;
}
.detail-smart {
  margin-top: 5px;
  display: flex;
  margin-right: 15px;
  margin-top: 10px;
}
.les-titres-smart {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 19px;
  color: #000000 !important;
}
.les-sous-titres {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;
  color: #000000 !important;
  margin-left: 10px;
}
.text-link-color {
  color: blue;
}
.container-rental {
  margin-top: 20px;
}
.container-rental {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.rental-sale {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.text-rental {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 154.4%;
  color: #06640c;
  margin-left: 10px;
}
.text-sale {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 154.4%;
  color: #828282;
  margin-left: 12px;
}
.container-element {
  margin-top: 20px;
  margin-left: 16px;
}
.bottom-card-item-agencie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 85%;
  bottom: 0;
  padding: 5px 0;
}
.les-sous-titres-color {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;
  color: blue !important;
  margin-left: 10px;
}
@media (max-width: 768px) and (min-width: 360px) {
  .les-titres-smart {
    font-size: 9px !important;
    color: red;
  }
  .les-sous-titres {
    font-size: 8px !important;
  }
}
