.contact-modal-para {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #06640c !important;
}
.form-label-text-modal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
}
/* .container-input-address-modal {
    padding-top: 10px;
  } */
.infos-perso-input-modal {
  margin-bottom: 2.5rem !important;
}
.text-label-modal {
  text-align: left !important;
}
.form-input-profile-modal {
  padding-top: 40px;
}

.btn-retour-message-modal {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 2px;
}
