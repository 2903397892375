.export-btn-newsletter{
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border-color: transparent !important;
    border-color: #9a160a !important;
    padding: 5px 20px;
}


  