.empty-fluidy {
  padding: 20px 125px;
  background: #fff !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.headering-admin {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.logo-item {
  font-size: 25px;
  color: #000;
}
.logo-container.dashboard-header-logo img {
  width: 100%;
}
.header-others-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.search-form-container {
  position: relative;
}
.search-form-content label {
  position: absolute;
  top: 25%;
  left: 4%;
}
input.form-control.search-input {
  // width: 325px;
  border-radius: 8px;
  padding: 30px 40px !important;
  background: #f5f5f7;
  border: none;
}
.notification-item-content {
  position: relative;
}
.notification-counter {
  position: absolute;
  display: inline-block;
  top: -4px;
  width: 20px;
  height: 20px;
  background: #dd3300;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  left: 50%;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
}
.notification-counter2 {
  position: absolute;
  display: inline-block;
  top: -15px;
  width: 20px;
  height: 20px;
  background: #dd3300;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  right: -5px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
}
img.user-avatar {
  border-radius: 8px;
}
svg.search-icony {
  width: 20px;
  height: 20px;
}
.user-acount-dashboard-header {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #011f3b;
  background: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.dropdown-menu-custom {
  border-radius: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.041);
  width: 215px;
  top: 100% !important;
  padding: 10px 0 !important;
  left: -80%;
  text-align: center;
}

.item-dropdown-custom {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffdd44;
  transition: 0.2s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffdd44 !important;
  color: #000 !important;
}

.item-dropdown-custom:hover {
  color: #000;
  background-color: #ffdd44;
  transition: 0.2s ease-in-out;
}
.logo-texte {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #a4a6b3;
  opacity: 0.7;
}
