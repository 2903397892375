.about-container {
	padding-top: 20vh;
}
.banniere-about-us {
	background-image: url(../../../images/ABOUT_auto.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: relative;
	background-position: center;
}
.sous-titre-about-us {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	text-align: center;
	color: #ffffff;
}
.titre-h3-theme {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: #cc1b0b;
}
.text-para-black-aboutus {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.01em;
	color: #000000;
}
.titre-meet-our-team {
	font-style: normal;
	font-weight: bold;
	font-size: 34px;
	line-height: 40px;
	letter-spacing: 0.1em;
	color: #ffffff;
}
.card-team-item {
	background: #fff;
	border: none;
}
.titre-theme-card {
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 39px;
	letter-spacing: 0.1em;
	color: #9a160a;
	text-align: center;
	/* white-space: nowrap; */
}
.text-name-card {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: 0.1em;
	color: #000000;
	text-align: center;
}
#__next {
	padding: 20px;
}
.image-item {
	padding: 20px 0 20px 20px;
}
.react-multi-carousel-track {
	display: flex;
}
.rec-arrow {
	display: none;
}
.fSoGyI {
	background: rgba(255, 255, 255, 0.58) !important;
	box-shadow: none !important;
}
.rec-dot_active {
	background: #ffffff !important;
	background-color: none !important;
	box-shadow: none !important;
}
.titre-equipe-about {
	font-style: normal;
	font-weight: bold;
	font-size: clamp(20px, 3vw, 36px);
	line-height: clamp(25px, 3vh, 42px);
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #000000;
}
.text-equipe-about {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 34px;
	text-align: justify;
	letter-spacing: 0.01em;
	color: #000000;
}
.text-color-link-about {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 34px;
	text-align: justify;
	letter-spacing: 0.01em;
	color: #cc1b0b;
}
.text-color-link-about:hover {
	font-weight: 300;
	color: #cc1b0b;
}
.img-logo-equipe {
	width: 250px;
	height: 200px;
}
.section-service {
	background: #cc1b0b;
}
.bloc-div-item-service {
	display: flex;
  flex-direction: column;
	background: #fff;
	padding: 1.5rem;
  align-items: center;
  gap: 1rem;
}
.titre-section-service {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: #000000;
}
.text-section-service {
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	text-align: justify;
	color: #000000;
}
.hover-card-team-item {
	display: none;
	background: rgba(0, 0, 0, 0.19) !important;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.bloc-card-team-item-hover:hover .hover-card-team-item {
	display: inline;
}
.bloc-card-team-item-hover {
	position: relative;
	/* width: 85%; */
	width: 100%;
	max-height: 850px;
}
.bloc-icon-social-team {
	position: absolute;
	top: 50%;
	left: 30%;
}

.height-card-body-team {
	min-height: 150px !important;
}
.his-text {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: clamp(12px, 1.5vw, 16px);
	line-height: 27px;
	letter-spacing: 0.01em;
	color: #000000;
	margin-bottom: 10px;
}
.his-steps-container::before {
	content: "";
	background: #c4bfbf;
	width: 1px;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	right: -1.5rem;
}
.his-step {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 7600;
	font-size: clamp(16px, 1.5vw, 20px);
	line-height: 34px;
	color: #bfb4b3;
	list-style: none;
}
.his-step.active {
	color: #cc1b0b;
	font-weight: 700;
}

.his-step.active::before {
	content: "";
	background: #cc1b0b;
	width: 4px;
	height: 30px;
	display: block;
	position: absolute;
	right: -1.55rem;
}
.taf-introduce {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(16px, 2vw,  21px);
	line-height: 31px;
	text-align: center;
	color: #000000;
}
