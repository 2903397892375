.projets-container {
  background-color: #e5e5e5;
  min-height: 100vh;
}

.dashboard-card-container {
  gap: 1.5rem;
  padding: 0;
}
.dashboard-card {
  background-color: #fff;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  width: 100%;
}

.dasboard-card-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #a0aec0;
  margin: 0;
  margin-bottom: 7px;
}

.dasboard-card-montant {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: rgb(100, 100, 100);
}

.dasboard-card-img-container {
  background: linear-gradient(180deg, #06640c 0%, #eb0508 0.01%, #061a07 100%);
  padding: 1rem;
  border-radius: 12px;
  color: #fff;
}

/* ----------Line chart--------------- */
.dashboard-chart-container {
  background: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  padding: 1.5rem 2rem;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .dashboard-card-container {
    flex-direction: column !important;
    gap: 1.5rem;
    width: 100%;
    margin: 10%;
  }
  .dashboard-chart-container {
    margin-left: 10%;
    width: 100%;
  }
}
