@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.card-checkout {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.checkout-title {
  font-size: 57px;
  font-style: normal;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #000000;
}
.proceed-payement {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #cc1b0b;
}

.checkout-bar {
  height: 1px;
  background: #e0e0e0;
}

/**** Order Title*/
.order-title {
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  color: #000000;
}
.form-dashed-group {
  position: relative;
}

.form-dashed-label,
.form-dashed-control {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #828282;
}

.form-dashed-label {
  position: absolute;
}

.form-dashed-control {
  width: 100% !important;
  outline: none !important;
  border: none !important;
  border-bottom: 1px dashed #828282 !important;
  text-align: right;
}

.check-title,
.check-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.have-coupon {
  background: rgba(238, 52, 52, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem 0;
  text-align: center;
}

.platinium-price {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  color: #06640c;
}

.feature-check-container {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3fff4;
  border-radius: 50%;
  color: #cc1b0b;
  margin-right: 5px;
}

.bo-b-g {
  border-bottom: 1px solid #e0e0e0;
}

/***************** Stepper ***********/
.checkout-step-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.checkout-stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  color: #ee3434;
  border: 2px solid #9f9f9f;
}

.checkout-stepper.active {
  color: #ffffff;
  background: #cc1b0b;
  border-color: #cc1b0b;
}

.step-label {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin: 0;
}

.form-check-title {
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  color: #000000;
}

/* **********Payment Information *************/
.card-check-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  color: #000000;
}

.card-quice-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  color: #000000;
}

.card-check-form-control {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100% !important;
  height: 52px;
  border: none;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  color: #000000;
  outline: none;
}

/**************** Coupon Confimation Style **********/

.done-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #cc1b0b;
  color: #ffffff;
  border: 2px solid #cc1b0b;
}

.done-title {
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: #000;
  text-align: center;
}

.done-message {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #000000;
  text-align: center;
}

.done-text {
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  color: #000000;
  text-align: left;
}

.checkout-data-container {
  margin: 3rem 0;
}
.label-check {
  position: absolute;
  top: -5px;
}

.form-custom-check {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  cursor: pointer;
  outline: none !important;
  width: 20px !important;
  height: 20px !important;
  border: 1.5px solid #bdbdbd;
  margin-right: 8px;
}

.form-custom-check:before {
  font: 13pt "FontAwesome";
  content: "\f00c";
  display: inline;
  position: absolute;
  top: 1.3px !important;
  color: #ffffff;
}

.form-custom-check:checked {
  background-color: #cc1b0b !important;
  border-color: #cc1b0b !important;
}

.border-full {
  border-radius: 50%;
}

.border-5 {
  border-radius: 5px;
}
