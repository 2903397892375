.titre-bloc-left-contact {
  font-style: normal;
  font-weight: bold;
  font-size: clamp(20px, 4vw, 36px);
  line-height: 47px;
  letter-spacing: 0.1em;
  color: #000000;
  margin: 0;
}
.text-bloc-left-contact {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 154.4%;
  color: #000000;
  text-align: justify;
}
.text-theme-contact {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100.4%;
  color: #cc1b0b;
}
.text-contact-black {
  font-style: normal;
  font-weight: normal;
  font-size: clamp(20px, 3vw, 24px);
  line-height: 154.4%;
  color: #000;
}
.input-form-contact {
  background: #ffffff;
  /* opacity: 0.3; */
  border: 1px solid #bdbdbd;
  box-sizing: border-box;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 154.4%;
  color: #828282;
  height: 70px;
}
.label-form-contact {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 154.4%;
  color: #333333;
}
.btn-theme-submit {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #cc1b0b;
  width: 100%;
  padding: 1rem;
}
.btn-theme-submit:hover {
  color: #cc1b0b;
  border: 1px solid #cc1b0b;
  background: #fff;
}
.p-contact {
  padding-top: 11.5rem;
}
