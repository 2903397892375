.containter-table-admin {
  margin-top: 90px;
}
.para-image-admin {
  margin-top: 75px;
}

.text-abdou {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.9);
  margin-top: 30px;
}
.information-personnelle-para {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
a,
a:link,
a:hover {
  text-decoration: none !important;
}
.info-personnelle-para {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.22);
}
.btn-modifier {
  width: 100%;
  height: 38px;
  background: #cc1b0b;
  border-radius: 4px;
  color: #fff;
  margin-top: 20px;
  font-size: 12px;
  text-align: center;
  border: none;
}

/* ----------les type radio------------- */
.form-group {
  margin-bottom: none !important;
}
.form-custom-para {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  cursor: pointer;
  outline: none !important;
  width: 20px !important;
  height: 20px !important;
  border: 1.5px solid #bdbdbd;
  margin-right: 8px;
}

.form-custom-para:before {
  font: 13pt "FontAwesome";
  content: "\f00c";
  display: inline;
  justify-content: center;
  align-items: center;
  top: 1.3px !important;
  color: #ffffff;
  margin-top: 120px;
}

.form-custom-para:checked {
  background-color: #cc1b0b !important;
  border-color: #cc1b0b !important;
  color: #868686;
}
.label-para {
  position: absolute;
  top: -5px;
}
.custom-label-para,
.custom-label sup {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  color: #868686;
}
.container-check-input {
  border: 1px solid #000;
  display: inline-block;
}
.form-control-para {
  position: relative;
  display: block;
  width: 80%;
  height: 40px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
}

.stepper-setting {
  background: none;
  border: none;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
  padding-bottom: 15px;
}

.stepper-active-setting {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #cc1b0b;
}

.settings-admin-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
}

.btn-another-element {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(204, 204, 204, 0.15);
  border: none;
  padding: 15px 0;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8b8b8b;
}
.icon-btn-another-element {
  color: #8b8b8b;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.contenu-btn-profile-icon {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 154.4%;
  display: flex;
  align-items: center;
  color: #9a160a !important;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}
.contenu-btn-profile-icon:hover {
  background: #9a160a !important;
  color: #fff;
}

.meet-team-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}

.form-control-showoff {
  border-radius: 10px;
}

.profile-containers-param {
  background: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  border-radius: 15px;
  padding: 1.5rem 2rem;
}

.img-card-partner {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding-bottom: 2rem;
  position: relative;
}

.img-card-partner-icons {
  position: absolute;
  right: 0.5rem;
  bottom: 0.3rem;
  display: flex;
  gap: 10px;
}

.icon-edit-img-card-partner {
  color: #061a07 !important;
}
.icon-trash-img-card-partner {
  color: #e53e3e !important;
}

.btn-add-amin-setting {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 154.4%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9a160a;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}
.btn-add-amin-setting:hover {
  background-color: #9a160a;
  color: #fff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
}

.btn-addadmin-icon {
  margin-right: 8px;
}

.div-btn-modal-addmember {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn-back-primary {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.045em;
  color: #515151;
  background: none;
  border: none;
  margin-right: 10px;
}

.container-input-modal-member {
  padding: 20px;
}

.btn-modal-arrowback {
  color: #666666;
  margin-right: 8px;
}

.modal-faq-header-add-member,
.modal-faq-footer-add-member {
  border: none;
}

.btn-taf-partner {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 15px;
  color: #0c0b0b;
  background: none;
  padding: 0.8rem 1.2rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-taf-partner-icon {
  color: #b0b0b0;
}

.btn-add-amin-setting-hide {
  display: none;
}

.admin-stepper-item {
  display: flex;
  gap: 30px;
}

@media (max-width: 768px) {
  .settings-admin-stepper {
    flex-direction: column;
    padding-top: 20px;
    justify-content: start;
    align-items: start;
    width: 100%;
  }

  .admin-stepper-item {
    display: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}
@media (max-width: 767px) {
  .profile-containers-param {
    padding: 1rem 0rem;
  }
}
