.users-container {
  background-color: #e5e5e5;
  padding: 2rem;
}

.liste-utilisateurs {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
}

.liste-utilisateurs-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2d3748;
  margin: 0;
}

.users-table {
  margin-top: 3rem;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 35%;
}
.title-page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .title-page-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
