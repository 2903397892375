.child-table-container {
  background-color: white;
}
.filtres-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 3rem;
}
.number-chat-link {
  position: relative;
  text-decoration: none;
}
.number-chat-link {
  position: relative;
  text-decoration: none;
}

.number-chat {
  position: absolute;

  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 8px;
  color: #ffffff;
  background-color: #e53e3e;
  border-radius: 12px;
  padding: 2px 3px;
  left: 12px;
  top: -2px;
  border: 1px solid #ffffff;
}
.name-email-group span {
  display: block;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.name-email-group span.name {
  color: #2d3748;
}

.table-actions-container {
  display: flex;
  align-items: center;
}

.cell-style {
  display: flex;
  align-items: center;
}
.filter-label {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.filter-input {
  width: 162px !important;
  height: 39px !important;
  background: #f7f7f7 !important;
  border-radius: 10px !important;
  outline: none;
  border: 1px solid #f7f7f7;

  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  color: #252733;
}

.filter-input-select-group {
  position: relative;
  cursor: pointer;
}
.projet-btn-nouveau {
  background: #06640c;
  border-radius: 20px;

  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: none;
  outline: none;
  border-radius: 100px;
  color: #ffffff;
}
.projet-btn-nouveau-rouge {
  background: #9b51e0;
  border-radius: 20px;

  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: none;
  outline: none;
  border-radius: 100px;
  color: #ffffff;
}
.projet-btn-nouveau-bleu {
  background: #2f80ed;
  border-radius: 20px;

  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: none;
  outline: none;
  border-radius: 100px;
  color: #ffffff;
}

@media screen and (max-width: 839px) {
  .filtres-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.nameFormatter {
  color: #000;
  font-weight: 500;
  font-size: 12px;
}

.name,
.nameFormation-noLink {
  white-space: normal;
  text-transform: uppercase;
  font-size: 12px;
}

.entreprise {
  color: rgba(0, 0, 0, 0.24) !important;
}
