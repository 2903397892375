.liste-projets {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
}
.sold-this-month {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #a0aec0;
  margin-left: 5px;
}

.projets-table {
  margin-top: 3rem;
}

.liste-projets-bar {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  align-items: center;
}

.liste-projets-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2d3748;
  margin: 0;
}

.liste-projets-statistiques {
  margin-top: 10px;
}

.icon-check-container {
  background: #115c67;
  color: #fff;
  margin-right: 5px;
  border-radius: 50%;
  padding: 1px 0.3px;
  font-weight: 500;
}

.number-project-text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #a0aec0;
}

.ajouter-projet-link {
  display: flex;
  align-items: center;
}

.ajouter-projet-link-icon {
  color: #b0b0b0;
  margin-right: 5px;
}

.ajouter-projet-link-text,
.ajouter-projet-link:hover {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #0c0b0b;
  text-decoration: none;
}

/************* Add Project Style *****************/

.add-projet-container {
  background-color: #e5e5e5;
  padding: 3rem;
}

.add-projet-content,
.detail-projet-content {
  background-color: #fff;
  padding: 1.5rem 4rem;
  border-radius: 10px;
}

.add-project-info-icon-title-container {
  display: flex;
  gap: 10px;
}

.add-project-info-stepper {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.add-project-info {
  display: flex;
  gap: 8px;
}

.add-project-icon {
  color: #200e32;
  line-height: 20px;
  cursor: pointer !important;
  display: inline-block;
}

.add-project-general-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2d3748;
  margin: 0;
}

.add-project-component-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  margin: 0;
}

.add-project-stepper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.stepper {
  height: 3px;
  min-width: 60px;
  border-radius: 25%;
  background-color: #c4c4c4;
}

.stepper-active {
  background-color: #115c67;
}

/******************** Details de base  ************************************************************/
.form-add-project-group {
  margin-bottom: 4rem;
  width: 100%;
}

.form-add-project-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2f3a4a;
}

.form-add-project-input {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 2px;
  height: 46px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2f3a4a;
  width: 90%;
}

.form-add-project-input::placeholder {
  color: #ababab;
}

.form-add-project-input-url {
  width: 95%;
}

.form-group-add-project-dropzone-container {
  width: 100%;
}

.form-add-project-buttons-container {
  display: flex;
  justify-content: flex-end;
  width: 97.2%;
  padding-top: 2rem;
}

.form-add-project-button-next {
  background: #115c67;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  outline: none;
  padding: 0.9rem 2rem;
}

.form-add-project-button-previous {
  background: transparent;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #115c67;
  margin-right: 2rem;
}
/***########### Input choose file Style ###################***/

.start-label-input-choose-file {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.025em;
  width: 100% !important;
}

.first_label-input-choose-file {
  width: 100% !important;
  display: block;
  color: #115c67;
  margin-top: 10px;
  margin-bottom: 3px;
}

.second-label-input-choose-file {
  color: #000;
  opacity: 0.5;
}

/***################ Details de base second Step***************/
.form-add-project-check-input-container {
  width: 90%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 0 1rem;
}
.form-add-project-check-input-container-border {
  width: 90%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #e8e8e8; */
  box-sizing: border-box;
  padding: 0 1rem;
}
.form-add-project-group-relative {
  position: relative;
}

.form-add-project-unity {
  display: inline-block;
  width: 60px;
  position: absolute;
  line-height: 35px;
  top: 3.5rem;
  right: 15%;
  color: #ababab;
  border-left: 1px solid #e8e8e8;
  padding-left: 7px;
  text-align: center;
  font-size: 12px;
}

.form-add-project-check-input-group {
  display: flex;
  align-items: center !important;
  gap: 5px;
}

.form-add-project-check-input-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ababab;
  margin: 0 !important;
}

.form-add-project-check-input {
  margin: 0 !important;
  outline: none !important;
  border: 2px solid #115c67;
  border-radius: 5px;
}

/* *################## Presentation de projet step 3 ###################*/
.form-add-project-input-textarea {
  height: 130px;
  min-height: 130px;
  max-height: 130px;
  outline: none;
  padding: 5px 10px;
  width: 95%;
}
.form-add-project-ckeditor {
  width: 96%;
}

/* *******Media Querie */
@media screen and (max-width: 767px) {
  .add-project-stepper {
    justify-content: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 497px) {
  .add-project-info {
    flex-direction: column;
  }
  .add-project-component-title {
    text-align: center;
  }

  .stepper {
    min-width: 30px;
  }

  .form-add-project-input {
    font-size: 10px;
    line-height: 20px;
    width: 100%;
    padding-left: 4px;
  }
  .form-add-project-check-input-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: 90px;
    padding: 5px;
  }

  .form-add-project-unity {
    width: 42px;
    font-size: 8px;
  }

  .form-add-project-label {
    font-size: 10px;
  }
  .form-add-project-group {
    padding: 0 !important;
  }
  .table-body-container-remboursement,
  .form-add-project-input-textarea {
    width: 100% !important;
  }

  .form-add-project-ckeditor {
    width: 100% !important;
    padding-right: 0;
  }
}

/**################### 	Detail-projet ############################*/
.detail-projet-info-actions {
  align-items: center;
}

.detail-projet-icon-title-container {
  display: flex;
  align-items: center;
}

.detail-projet-name-projet {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: rgba(0, 0, 0, 0.8);
}

.detail-projet-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: -0.025em;
  color: #737373;
}

.detail-projet-actions {
  display: flex;
  justify-content: flex-end;
}

.detail-projet-text-container {
  padding-left: 2.5rem;
}

.link-detail-projet {
  color: #115c67;
}

.detail-projet-info-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.detail-projet-info-val {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.detail-projet-resume-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.detail-projet-resume-progress-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-projet-resume-percentage {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #757575;
}

.detail-projet-resume-solde {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: #115c67;
}
.detail-projet-resume-objectif-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}

.detail-projet-resume-info-porteur-projet {
  display: flex;
  align-items: center;
  gap: 8px;
}

.detail-projet-resume-avatar-porteur-projet {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0;
}

.detail-projet-resume-name-porteur-projet {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: #010101;
}

.detail-projet-resume-cat-porteur-projet {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.025em;
  color: #010101;
}

.detail-projets-tabs-container {
  margin-top: 3rem;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.4rem;
}

.detail-projets-tabs {
  display: flex;
  align-items: center;
  gap: 12px;
}

.detail-projets-tabs-item {
  list-style: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 0 1rem;
}

.detail-projets-tabs-item.active-tab {
  background: #f3f3f4;
  border-radius: 10px;
  color: #115c67;
  font-weight: bold;
  padding: 1rem;
}

.detail-projet-title-desc {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #000000;
}

.detail-projet-desc-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: #2d2d2d;
  text-align: justify;
}

.detail-projet-desc-objectif {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: #000000;
}

.detail-projet-desc-info-val {
  color: #115c67;
  margin-left: 5px;
}

/** Detail Projet Donnees financieres */
.detail-projet-finance-data-title {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10%;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-avatar-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.detail-projet-finance-data-donneur-image {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.detail-projet-finance-data-donneur-name {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #171d33;
  margin: 0;
}

.detail-projet-finance-data-donneur-date-donnation {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}

.detail-projet-finance-data-donneur-montant-donnation {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #171d33;
  margin: 0;
}

/* * Detail projet document */

.link-add-doc-button {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ababab;
  padding: 1rem;
}
.btn-add-listing {
  border: none;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #0c0b0b !important;
}
.btn-add-listing:hover {
  text-decoration: none;
}
.card-details-listing {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.project-container {
  background: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  margin-top: 3rem;
}

.img-detail-listing {
  width: 100%;
  height: 100%;
}
.detail-listing-img {
  margin-top: 10px;
}
.image-listing-icon {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 10px;
}
.cla-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #000000;
  text-align: justify;
}
.text-section-services {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #828282;
  text-align: justify;
}
.text-date-listing {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #06640c;
  margin-top: 5px;
}
.text-retour {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #4f4f4f;
  font-size: 10px;
}
.image-vector {
  margin-right: 10px;
}

.container-profile-listng-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .container-profile-listng-admin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container-profile-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
