.name-user-profil {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 154.4%;
  color: #333333;
  text-align: center;
}
.navlink-tab-profil-user {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 154.4%;
  color: #828282;
  background: transparent;
}
.navlink-tab-profil-user.active {
  color: #06640c !important;
  font-weight: 600;
  background: transparent !important;
}
.navlink-tab-profil-user:hover {
  color: #06640c !important;
  font-weight: 600;
}
.bloc-img-profil-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profil-user-img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}
.bloc-navlink-profil {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem 0.7rem;
}
.btn-change-mdp {
  padding: 0.5rem 1rem;
}
.btn-delete-listing {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 154.4%;
  color: #ee3434;
}
.icon-btn {
  background: rgba(238, 52, 52, 0.2);
}
.width-btn {
  width: 20%;
}
